<template>
    <div>
       <div class="numberEcharts" :style="{ height: echartsHeight }" ref="numberEcharts" id="numberEcharts" v-show="numberData.length"></div>
    </div>
 
</template>

<script>
export default {
   props:['numberData',"istitle","lenged",'echartsHeight'],
   data(){
       return{
           
       }
   },
   watch:{
    numberData:function(newval,oldval){
           this.getNumberCondition()
       },
    //    istitle:function(newval,oldval){
    //        this.getNumberCondition()
    //    },
    //    lenged:function(newval,oldval){
    //        this.getNumberCondition()
    //    },
    //    echartsHeight:function(newval,oldval){
    //        this.getNumberCondition()
    //    },
   },
   mounted(){this.getNumberCondition()},
   methods:{
    getNumberCondition() {
      if (!this.numberData.length) return;
      this.$nextTick(() => {
        let numberEcharts = this.$echarts.init(
         this.$refs.numberEcharts
        );
        // 对数据进行处理
        let xAxisData = [];
        let seriesOrder_numData = [];
        let seriesSell_amoutData = [];
        this.numberData.forEach((item) => {
          xAxisData.push(item.date);
          seriesOrder_numData.push(item.order_num);
          seriesSell_amoutData.push(item.sell_amout);
        });
        var legend = ""
        if (this.lenged) {
            legend={
            data: [
              {
                name: "销售额",
                icon: "circle",
                color: "#2dc079",
              },
              {
                name: "订单数量",
                icon: "circle", // 用圆点替换
              },
            ],
            left: "center",
            bottom: 0,
          }
        }else{
            legend={
            data: [
              {
                name: "销售额",
                icon: "circle",
                color: "#2dc079",
              },
              {
                name: "订单数量",
                icon: "circle", // 用圆点替换
              },
            ],
            right: 20,
         
         
          }
        }
        let title=this.istitle?'销售额与订单数量对比分析图':''
        let numberEchartsOption = {
          title: {
            text: title,
          },
          color: ["#2dc079", "#2F80ED"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: 'line',
    }
          },
          legend,
          xAxis: {
            type: "category",
            data: xAxisData,
            axisTick: {
              show: false,
            },
            axisLine: {
              onZero: true,
              lineStyle: {
                color: "#999", //刻度线的颜色
              },
            },
            axisLabel: {
              // interval: intervalData,
              // rotate:30
            },
            offset: 10,
            textStyle: {
              color: "#ccch", //文字颜色
              fontSize: "6", //文字大小
            },
          },
          yAxis: {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              onZero: true,
              lineStyle: {
                color: "#999", //刻度线的颜色
              },
            },
            name: "单位",
          },
          series: [
            {
              name: "销售额",
              data: seriesSell_amoutData,
              type: "bar",
              smooth: true,
              symbol: "none",
              stack: "Search Engine",
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: "#2dc079",
                  },
                },
              },
            },
            {
              name: "订单数量",
              data: seriesOrder_numData,
              type: "bar",
              smooth: true,
              symbol: "none",
              stack: "Search Engine",
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: "#2F80ED",
                  },
                },
              },
            },
          ],
        };
        numberEcharts.setOption(numberEchartsOption);
        window.addEventListener("load", function () {
          numberEcharts.resize();
        });
        window.addEventListener("resize", function () {
          numberEcharts.resize();
        });
      });
    },
   },

}
</script>

<style lang="scss" scoped>
  .numberEcharts {
    // height: 350px;
    padding-right: 20px;
    box-sizing: border-box;
  }
</style>
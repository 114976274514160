<template>
  <div>
    <div
      class="orderEcharts"
      :style="{ height: echartsHeight }"
      ref="orderEcharts"
      id="saleEcharts"
      v-show="orderData.length"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["orderData", "istitle", "lenged", "echartsHeight"],
  data() {
    return {};
  },
  watch: {
    orderData: function (newval, oldval) {
      this.getOrederCondition();
    },
    // istitle:function (newval, oldval) {
    //   this.getOrederCondition();
    // },
    // echartsHeight:function (newval, oldval) {
    //   this.getOrederCondition();
    // },
    // lenged:function (newval, oldval) {
    //   this.getOrederCondition();
    // },
  },
  mounted() {
    this.getOrederCondition();
  },
  methods: {
    getOrederCondition() {
      if (!this.orderData.length) return;
      this.$nextTick(() => {
        let orderEcharts = this.$echarts.init(this.$refs.orderEcharts);

        // 获取到x轴
        let xAxisData = [];
        let seriesOrderData = [];
        let seriesUserData = [];
        this.orderData.forEach((item) => {
          xAxisData.push(item.date);
          seriesOrderData.push(item.order_num);
          seriesUserData.push(item.user_inc_num);
        });
        let intervalData = Math.floor(xAxisData.length / 9);
        // console.log(intervalData, "订单数量");
        var legend = ""
        if (this.lenged) {
          legend = {
            data: [
              {
                name: "订单数量",
                icon: "circle",
                color: "#2dc079",
              },
              {
                name: "用户增长",
                icon: "circle", // 用圆点替换
              },
            ],
            left: "center",
            bottom: 0,
          };
        } else {
          legend = {
            data: [
              {
                name: "订单数量",
                icon: "circle",
                color: "#2dc079",
              },
              {
                name: "用户增长",
                icon: "circle", // 用圆点替换
              },
            ],
            right: 20,
          };
        }
        let title=this.istitle?'订单数量与用户增长对比分析图':''
        let orderEchartsOption = {
          title: {
            text: title,
          },
          color: ["#2dc079", "#2F80ED"],
          tooltip: {
            trigger: "axis",
            //           formatter: function(params) {
            // var result = params[0].name + '<br/>';
            // result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#2dc079"></span>订单数量:' + params[0].value + '<br/>';
            // result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#2F80ED"></span>用户增长:' + params[1].value;
            // return result;
            // }
          },
          legend: legend,
          xAxis: {
            type: "category",
            data: xAxisData,
            axisTick: {
              show: false,
            },
            axisLine: {
              onZero: true,
              lineStyle: {
                color: "#999", //刻度线的颜色
              },
            },
            axisLabel: {
              // interval: intervalData,
              // rotate:30
              // textStyle: {
              //   color: "#999",
              // },
            },
            offset: 10,
            textStyle: {
              color: "#ccch", //文字颜色
              fontSize: "6", //文字大小
            },
          },
          yAxis: {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              onZero: true,
              lineStyle: {
                color: "#999", //刻度线的颜色
              },
            },
            name: "单位",
          },
          series: [
            {
              name: "订单数量",
              data: seriesOrderData,
              type: "line",
              smooth: true,
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: "#2dc079",
                  },
                },
              },
            },
            {
              name: "用户增长",
              data: seriesUserData,
              type: "line",
              smooth: true,
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: "#2F80ED",
                  },
                },
              },
            },
          ],
        };
        orderEcharts.setOption(orderEchartsOption);
        window.addEventListener("load", function () {
          orderEcharts.resize();
        });
        window.addEventListener("resize", function () {
          orderEcharts.resize();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.saleEcharts,
.orderEcharts {
  // width: 200px;
  //    height: 360px;
  padding-right: 20px;
  box-sizing: border-box;
}
</style>